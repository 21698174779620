import { graphql } from 'gatsby'

import Homepage from './index'

export const query = graphql`
  query HomePageSageQuery {
    Slides: allBannerSlidesYaml {
      nodes {
        text
        buttonText
        buttonLink
      }
    }
    Testimonials: allTestimonialsYaml {
      nodes {
        author
        text
      }
    }
  }
`

export default Homepage